<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(createUser)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <users-form
          :user-data="userData"
          @update-avatar="updateAvatar"
          @remove-avatar="removeAvatar"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import UsersForm from '../users-form/UsersForm.vue'
import useUsersForm from '../users-form/useUsersForm'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    UsersForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },

  setup() {
    const {
      userData,

      updateAvatar,
      removeAvatar,
      createUser,
    } = useUsersForm()

    return {
      //  Data
      userData,

      //  Action
      updateAvatar,
      removeAvatar,
      createUser,

    }
  },
}
</script>
